import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs';
import { ServiceModalComponent } from '../../modals/service-modal/service-modal.component';
import { AdditionalServicesService } from '../../../services/api/additional-services';
import { RefundServiceModalComponent } from '../../modals/refund-service-modal/refund-service-modal.component';
import { SnackService } from '../../../services/helpers/snack.service';

export interface Service {
  title: string;
  status: string;
  created: string;
  price: number;
  currency: string;
  deleted: string;
  tokens: number;
}

@Component({
  selector: 'lib-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServicesComponent {

  services: Service[] = []
  statusLabels: any = {
    draft: 'draft',
    waitingForPayment: 'waiting payment'
  }

  @Input() bookingId?: string;
  @Output() onBalanceUpdate = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    private additionalServicesService: AdditionalServicesService,
    private snackService: SnackService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    console.log('this.bookingId', this.bookingId)
  }

  ngOnChanges(changes: any): void {
    if (changes.bookingId && !changes.bookingId.firstChange) {
      this.getAdditionalServices();
    }
  }

  onWidgetExpand(): void {
    if (!this.services?.length) {
      this.getAdditionalServices()
    }
  }

  getAdditionalServices(): void {
    if (this.bookingId) {
      this.additionalServicesService.getAdditionalServices(this.bookingId).pipe(first()).subscribe((services: any) => {
        this.services = services.sort((a: any, b: any) => new Date(a.created).getTime() - new Date(b.created).getTime());
        this.cd.detectChanges()
      })
    }
  }

  chargeService(service: any): void {
    this.additionalServicesService.chargeService(service.id).pipe(first()).subscribe((result: any) => {
      if (result === 'additionalFundsNeeded') {
        this.snackService.systemError('Not enough balance');
      } else {
        this.onBalanceUpdate.emit()
      }

      this.getAdditionalServices()
    })
  }

  cancelService(service: any): void {
    if (service.status !== 'paid') {
      this.additionalServicesService.cancelService(service.id).pipe(first()).subscribe((result: any) => {
        this.getAdditionalServices()
      })
    } else {
      const dialogRef = this.dialog.open(RefundServiceModalComponent, {
        width: '486px',
        data: { bookingId: this.bookingId, service },
        panelClass: 'no-padding-dialog',
        autoFocus: false
      });
      dialogRef.afterClosed().pipe(first()).subscribe((shouldRefetch: boolean) => {
        this.onBalanceUpdate.emit()

        if (shouldRefetch) {
          this.getAdditionalServices()
          this.cd.detectChanges()
        }
      })
    }
  }

  openServiceModal(service?: any): void {
    const readOnly = service && (service.deleted || (service.status !== 'draft' && service.status !== 'waitingForPayment'))
    const dialogRef = this.dialog.open(ServiceModalComponent, {
      width: '486px',
      data: { bookingId: this.bookingId, service, readOnly },
      panelClass: 'no-padding-dialog',
      autoFocus: false
    });
    dialogRef.afterClosed().pipe(first()).subscribe((shouldRefetch: boolean) => {
      this.onBalanceUpdate.emit()

      if (shouldRefetch) {
        this.getAdditionalServices()
        this.cd.detectChanges()
      }
    })
  }
}

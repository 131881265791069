import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AbstractControl, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import firebase from 'firebase/compat/app';
import { BehaviorSubject, debounce, debounceTime, first, map, Subject, takeUntil, tap, timer } from 'rxjs';
import { Booking } from '../../interfaces/Booking';
import { ChatDetailedInfo } from '../../interfaces/ChatDetailedInfo';
import { ChatMessageData } from '../../interfaces/ChatMessage';
import { Client } from '../../interfaces/Client';
import { Operator } from '../../interfaces/Operator';
import { User } from '../../interfaces/User';
import { ChatService } from '../../services/api/chat.service';
import { UserService } from '../../services/api/user.service';
import { TimeService } from '../../services/helpers/time.service';
import Reference = firebase.database.Reference;
import { ClientsService } from '../../services/api/clients.service';

@Component({
  selector: 'lib-chat-component',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
  @Input() offerId?: string;
  @Input() isHidden?: boolean;

  curUser!: User;
  booking!: Booking;
  isOperator = false;
  errorMsg = '';
  readByVisible = null;
  usersById = {}

  chatMessages$ = new BehaviorSubject<Array<ChatMessageData>>([]);
  client?: Client;
  operator?: Operator;
  chatForm = new UntypedFormGroup({
    message: new UntypedFormControl(null, this.validatorTrim),
  });
  editingMessageId = '';
  chatId = '';
  isTyping = false;

  @ViewChild('tooltip') tooltip!: ElementRef;
  @ViewChild('chatcontent') chatcontent!: ElementRef;
  unsubscribe$ = new Subject<void>;
  chatRef?: Reference;
  chatUsersByFirebaseId: { [x: string]: User; } = {};
  expanded: any[] = [];

  private unreadMessageCallback: ((count: number) => void) | null = null;

  constructor(
    public afAuth: AngularFireAuth,
    public timeService: TimeService,
    public userService: UserService,
    public clientsService: ClientsService,
    private metaService: Meta,
    private chatService: ChatService,
    private cd: ChangeDetectorRef,
  ) {
    this.isOperator = this.userService.isOperator;
    this.metaService.addTag({ name: 'theme-color', content: '#192d50' });
    document.documentElement.classList.add('ofy-hide');
    document.body.classList.add('ofy-hide');
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const tooltipElement = this.tooltip?.nativeElement;
    if (tooltipElement && !tooltipElement.contains(event.target as Node)) {
      this.readByVisible = null; 
    }
  }

  ngOnInit(): void {
    this.getUserInfo();
  }

  ngOnChanges(): void {
    if (this.offerId && this.curUser) {
      this.loadChat(this.offerId);
    }
  }

  ngOnDestroy(): void {
    this.metaService.removeTag('name=theme-color');
    document.documentElement.classList.remove('ofy-hide');
    document.body.classList.remove('ofy-hide');
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.isOperator) {
      this.chatService.unSubscribeChat(this.chatId);
    }
    if (this.chatRef && this.unreadMessageCallback) {
      this.chatService.unSubscribeUnreadMessages(this.chatRef, this.unreadMessageCallback);
    }
  }

  getUserInfo(): void {
    this.userService.getUserInfo()
      .pipe(first())
      .subscribe(resp => {
        this.curUser = resp;
        if (this.offerId) {
          this.loadChat(this.offerId);
        }
      });
  }

  loadChat(offerId: string): void {
    this.afAuth.user.pipe(first())
      .subscribe(() => {
        this.chatId = offerId
        if (this.chatId) {
          this.chatService.createChat(this.chatId)
            .pipe(first())
            .subscribe(() => {
              this.chatService.onChatChanges(this.chatId, (messages) => {
                messages.forEach((m: any) => {
                  m.readBy && Object.keys(m.readBy).forEach(uid => {
                    if (!this.chatUsersByFirebaseId[uid]) {
                      this.userService.getUserById(uid).pipe(first()).subscribe((user) => {
                          this.chatUsersByFirebaseId[uid] = user as any
                      })
                    }
                  })
                })

                this.updateMessagesList(messages)
              });
              this.chatService.getChatById(offerId)
                .pipe(first())
                .subscribe(
                  (chatInfo) => {
                    this.client = chatInfo.client;
                    this.operator = chatInfo.operator;
                    this.chatUsersByFirebaseId[this.userService.userInfo$.value.firebaseId] = this.userService.userInfo$.value;

                    this.usersByFirebaseId(chatInfo);
                    this.cd.detectChanges();
                  });
            });
        }
        this.chatForm.get('message')?.valueChanges
          .pipe(
            tap(() => {
              if (!this.isTyping) {
                this.isTyping = true;
                this.chatService.setTyping(this.chatId, this.curUser.firebaseId, true);
              }
            }),
            debounceTime(5000),
            tap(() => {
              this.isTyping = false;
              this.chatService.setTyping(this.chatId, this.curUser.firebaseId, false);
            })
          ).subscribe();
        this.chatMessages$
          .pipe(
            takeUntil(this.unsubscribe$),
            map(results => {
              return results.sort((a, b) => new Date(a.sentDate).getTime() - new Date(b.sentDate).getTime())
            }),
            debounce(() => timer(500)),
            tap(() => {
              this.scrollToBottomOfChat();
            }),
          ).subscribe();
        
        this.unreadMessageCallback = this.onUnreadMessage.bind(this);
        this.chatRef = this.chatService.subscribeSpecificChatUnreadMessages(this.chatId, this.curUser.firebaseId, this.unreadMessageCallback);
      });
  }

  onUnreadMessage(): void {
    if (!this.isHidden) {
      this.chatService.removeUnread(this.chatId, this.curUser.firebaseId);
    }
  }

  usersByFirebaseId(chat: ChatDetailedInfo): void {
    [
      ...chat.client?.admins || [],
      ...chat.client?.owners || [],
      ...chat.operator?.admins || [],
      ...chat.operator?.owners || []
    ].forEach(user => {
      this.chatUsersByFirebaseId[user.firebaseId] = user;
    });
  }

  toggleExpand(value: boolean, legIngex: number, type: string, pIndex: number): void {
    if(value) {
      if (!this.expanded[legIngex][type].includes(pIndex)) this.expanded[legIngex][type].push(pIndex)
    } else {
      this.expanded[legIngex][type].splice(this.expanded[legIngex][type].indexOf(pIndex), 1);
    }
  }


  scrollToBottomOfChat(): void {
    this.cd.detectChanges();
    const chatContainer = document.getElementsByClassName('mat-tab-body-content')[3];
    if (chatContainer && this.chatcontent) chatContainer.scrollTop = this.chatcontent.nativeElement.scrollHeight;
  }

  updateMessagesList = (messages: ChatMessageData[]): void => {
    this.chatMessages$.next(messages);
    this.cd.detectChanges();
  };

  validatorTrim(formControl: AbstractControl): ValidationErrors | null {
    if (!formControl.value) return null;
    const isWhitespace = (formControl.value).trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  editMessage(message: ChatMessageData): void {
    this.chatForm?.get('message')?.setValue(message.txt)
    this.editingMessageId = message.id || ''
    this.cd.detectChanges();
  };

  saveEditingMessage(): void {
    const newMessage = this.chatForm?.get('message')?.value
    this.chatService.updateMessage(this.chatId, this.editingMessageId, newMessage)
    this.editingMessageId = ''
    this.chatForm?.reset()
  };

  cancelEdit(): void {
    this.editingMessageId = ''
    this.chatForm?.reset()
  }

  onFormSubmit(formDirective: FormGroupDirective): void {
    this.chatService.sendMessage(
      this.chatId,
      {
        senderId: this.curUser.firebaseId,
        senderName: this.curUser.firstName && this.curUser.lastName ? `${this.curUser.firstName} ${this.curUser.lastName}` : this.curUser.email,
        sentDate: new Date().toISOString(),
        txt: this.chatForm.value.message.trim()
      } as ChatMessageData
    )
      .then(() => {
        this.chatService.sendChatMessage(this.chatId, this.chatForm.value.message.trim())
          .pipe(first())
          .subscribe();
        formDirective.resetForm();
        this.chatForm.reset();
        this.isTyping = false;
        this.chatService.setTyping(this.chatId, this.curUser.firebaseId, false);
      })
      .catch(err => {
        formDirective.resetForm();
        this.chatForm.reset();
        console.error(err);
      });
  }

  toggleReadBy(message: any) {
    this.readByVisible = this.readByVisible === message.id ? null : message.id
  }

  getReaders(readBy: any) {
    return Object.keys(readBy)
  }

  sameWithPreviousDate(i: number): boolean {
    if (i === 0) return false;
    return this.chatMessages$.value[i - 1].sentDate.toString().split('T')[0] === this.chatMessages$.value[i].sentDate.toString().split('T')[0];
  }

  sameWithPreviousSender(i: number): boolean | string {
    if (i === 0) return false;
    return this.chatMessages$.value[i - 1].senderId === this.chatMessages$.value[i].senderId && this.sameWithPreviousDate(i) ? true : false;
  }
}

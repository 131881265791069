<div class="flight-profile">
  <div class="flight-profile__topbar">
    <a class="topbar-logo" [routerLink]="isOperator ? '/bookings' : '/bookings/all-bookings'" [queryParams]="!isOperator ? { booking: booking?.id } : {}">
      <img class="mr-20" src="assets/img/gold-logo.svg" width="43" height="38" alt="">
      <img src="assets/img/opes-jet.svg" width="140" height="21" alt="">
    </a>
    <div class="topbar-title">
      <div class="title-flight">
        <span>Flight</span>
        <lib-popup-default *ngIf="booking" [head]="booking.frpId.toUpperCase()" [copyOnClick]="booking.frpId" description="Copy Charter request ID to Clipboard by Clicking" class="title-flight__content">
          <div>{{booking.frpId.slice(0,7)}}</div>
        </lib-popup-default>
      </div>
      <div class="title-flight">
        <span>Confirmed price</span>
        <div class="title-flight__content">
          <span>{{items[0]?.price | number}}</span>
          <span class="currency"> {{items[0]?.priceCurCode}}</span>
        </div>
      </div>
    </div>
    <div class="topbar-info">
      <div *ngIf="isOperator; else superBroker" class="col ai-fe">
        <div class="topbar-info__operator">{{curUser?.operator?.name || curUser?.email}}</div>
        <div class="info-content">
          <span class="info-content__status" *ngIf="curUser?.operator?.status !== 'gray'" [class.gold]="curUser?.operator?.status === 'gold'" [class.silver]="curUser?.operator?.status === 'silver'" [class.bronze]="curUser?.operator?.status === 'bronze'">{{curUser?.operator?.status}}</span>
        </div>
      </div>
      <ng-template #superBroker>
        <div class="col ai-fe">
          <div class="topbar-info__role">Super Admin</div>
          <div class="topbar-info__name">{{curUser?.email}}</div>
        </div>
      </ng-template>
      <div class="topbar-info__avatar">
        <img width="44" height="44" [src]="curUser?.operator?.logo|| 'assets/img/plane-with-border.svg'" alt="">
      </div>
    </div>
  </div>
  <div class="flight-profile__container">
    <div class="left">
      <mat-tab-group mat-align-tabs="center">
        <mat-tab label="Flight Information">
          <div class="content">
            <div class="d-f jc-sb ai-c mb-10">
              <div class="fs-20 fw-500">{{operatorName}}</div>
              <div *ngIf="items[0]?.refNumber" class="fs-18 fw-500 op-04">Ref № {{items[0].refNumber}}</div>
            </div>
            <div *ngFor="let leg of items; index as i" class="leg">
              <div class="leg__title-text">LEG - {{i+1}}</div>
              <div class="leg__field">
                <img src="assets/img/plane.svg" width="44" height="44" alt="">
                <div class="ml-25">
                  <div class="name">{{leg.aircraftInfo.tailNumber}}</div>
                  <div class="value">{{leg.aircraftInfo.typeName}}</div>
                </div>
              </div>
              <div class="leg__field">
                <img src="assets/img/arrow-up-right.svg" width="44" height="44" alt="">
                <div *ngIf="leg.fromCityOnly" class="ml-25">
                  <div class="name">Departure {{leg.from?.city}} ( {{leg | airportDateTime: 'from' : 'EEEE dd.MM.yyyy ' + timeService.timeFormat}} LT )</div>
                  <div class="value">
                    <span>{{leg.from?.city}}</span>
                    <span *ngIf="leg.from?.province">, {{leg.from?.province}}</span>
                    <span>, {{leg.from?.countryName}}</span>
                  </div>
                </div>
                <div *ngIf="!leg.fromCityOnly" class="ml-25">
                  <div class="name">Departure {{leg.from?.icao}} ( {{leg | airportDateTime: 'from' : 'EEEE dd.MM.yyyy ' + timeService.timeFormat}} LT )</div>
                  <div class="value">{{leg.from?.name}}, {{leg.from?.city}}, {{leg.from?.countryName}}</div>
                </div>
              </div>
              <div class="leg__field">
                <img src="assets/img/arrow-down-right.svg" width="44" height="44" alt="">
                <div *ngIf="leg.toCityOnly" class="ml-25">
                  <div class="name">Destination {{leg.to?.city}} ( {{leg | airportDateTime: 'to' : 'EEEE dd.MM.yyyy ' + timeService.timeFormat}} LT )</div>
                  <div class="value">
                    <span>{{leg.to?.city}}</span>
                    <span *ngIf="leg.to?.province">, {{leg.to?.province}}</span>
                    <span>, {{leg.to?.countryName}}</span>
                  </div>
                </div>
                <div *ngIf="!leg.toCityOnly" class="ml-25">
                  <div class="name">Destination {{leg.to?.icao}} ( {{leg | airportDateTime: 'to' : 'EEEE dd.MM.yyyy ' + timeService.timeFormat}} LT )</div>
                  <div class="value">{{leg.to?.name}}, {{leg.to?.city}}, {{leg.to?.countryName}}</div>
                </div>
              </div>
              <div class="leg__field">
                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="33.9727" y="31" width="4.55365" height="4.55365" fill="#B4A27F"/>
                  <rect x="4" y="31" width="4.55365" height="4.55365" fill="#B4A27F"/>
                  <path d="M4.58447 33H38.5264" stroke="#B4A27F" stroke-dasharray="2 2"/>
                  <circle cx="21" cy="16" r="11.5" stroke="#B4A27F"/>
                  <path d="M22.9524 17.1905C22.4001 17.1905 21.9524 16.7428 21.9524 16.1905C21.9524 15.6382 22.4001 15.1905 22.9524 15.1905L28.5714 15.1905C29.1237 15.1905 29.5714 15.6382 29.5714 16.1905C29.5714 16.7428 29.1237 17.1905 28.5714 17.1905L22.9524 17.1905Z" fill="#B4A27F"/>
                  <path d="M21.1905 14.9286C21.1905 15.2047 20.9666 15.4286 20.6905 15.4286C20.4143 15.4286 20.1905 15.2047 20.1905 14.9286L20.1905 6.78571C20.1905 6.50957 20.4143 6.28571 20.6905 6.28572C20.9666 6.28572 21.1905 6.50957 21.1905 6.78572L21.1905 14.9286Z" fill="#B4A27F"/>
                  </svg>

                <div class="ml-25">
                  <div class="name">Estimated flight time</div>
                  <div class="value">{{leg.flightTime}}</div>
                </div>
              </div>
              <div class="leg__field">
                <img src="assets/img/users.svg" width="44" height="44" alt="">
                <div class="ml-25">
                  <div class="name">Passengers</div>
                  <div class="value">
                    <span *ngIf="leg.adultsNumber">{{leg.adultsNumber}} Adults</span>
                    <span *ngIf="leg.childrenNumber"> {{leg.childrenNumber}} Children</span>
                    <span *ngIf="leg.infantsNumber"> {{leg.infantsNumber}} Infants</span>
                  </div>
                </div>
              </div>
              <div *ngIf="leg.pets" class="leg__field">
                <img src="assets/img/pets.svg" width="44" height="44" alt="">
                <div class="ml-25">
                  <div class="name">Pets</div>
                  <div class="value">{{leg.petsDescription || 'No pets description'}}</div>
                </div>
              </div>
              <div *ngIf="leg.cargo" class="leg__field">
                <img src="assets/img/comments.svg" width="44" height="44" alt="">
                <div class="ml-25">
                  <div class="name">Comments/Requests</div>
                  <div class="value">{{leg.cargoDescription || 'No comments'}}</div>
                </div>
              </div>
              <div *ngIf="leg.emptyLeg" class="leg__field">
                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M34.488 27.5398V29.418H25.7791V27.5398H34.488ZM26.2336 12V29.418H23.9248V12H26.2336Z" fill="#B4A27F" />
                  <path d="M21.0657 27.5398V29.418H11.8423V27.5398H21.0657ZM12.3088 12V29.418H10V12H12.3088ZM19.8455 19.4888V21.3669H11.8423V19.4888H19.8455ZM20.946 12V13.8901H11.8423V12H20.946Z" fill="#B4A27F" />
                </svg>
                <div class="ml-25">
                  <div class="name">Condition</div>
                  <div class="value">Empty LEG</div>
                </div>
              </div>
              <div *ngIf="leg.description" class="leg__field" style="align-items: flex-start;">
                <img src="assets/img/comments.svg" width="44" height="44" alt="">
                <div class="ml-25">
                  <div class="name">{{leg.description}}</div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Client Information" class="client" *ngIf="booking?.client && booking?.status !== 'WaitingForServiceFee'">
          <div class="client-information">
            <div class="information-title">
              <div class="title-icon">
                <div class="icon-container">
                  <div class="icon-container__name sentry-mask" *ngIf="clientInfo?.firstName && clientInfo?.lastName">
                    {{clientInitials}}
                  </div>
                  <div class="icon-container__name" *ngIf="!clientInfo?.firstName || !clientInfo?.lastName">
                    <mat-icon>person</mat-icon>
                  </div>
                </div>
              </div>
            </div>
            <div class="information-description">
              <div class="description-row">
                <span class="description-row__title">Title</span>
                <div class="description-row__value sentry-mask">{{clientInfo?.title}}</div>
              </div>
              <div class="description-row">
                <span class="description-row__title">Name</span>
                <div class="description-row__value sentry-mask">{{clientInfo?.firstName}}</div>
              </div>
              <div class="description-row">
                <span class="description-row__title">Last name</span>
                <div class="description-row__value sentry-mask">{{clientInfo?.lastName}}</div>
              </div>
              <div class="description-row">
                <span class="description-row__title">Phone</span>
                <div class="description-row__value sentry-mask">{{clientInfo?.phoneNumber}}</div>
              </div>
            </div>
          </div>
          <lib-billing-info [client]="booking.client" [expanded]="true"></lib-billing-info>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="right">
      <mat-tab-group preserveContent="true" (selectedTabChange)="onTabChange($event)">
        <mat-tab label="Pax & Flight Documents">
          <div class="right-header" *ngIf="booking?.agreement&&!booking?.agreementSigned&&booking?.status !== 'WaitingForServiceFee'">
            Waiting for user signature
          </div>
          <div class="right-header" *ngIf="booking?.status === 'WaitingForPayment' && booking?.invoice && !booking?.paymentConfirmation">
            Waiting for payment
          </div>
          <div class="right-header" *ngIf="booking?.status === 'WaitingForServiceFee'">
            Waiting for service fee
          </div>
          <div class="right-content" [class.header-added]="booking?.status === 'WaitingForServiceFee' || booking?.status === 'WaitingForPayment' && booking?.invoice && !booking?.paymentConfirmation || booking?.agreement&&!booking?.agreementSigned">
            <div class="content-documents">
              <div class="documents-row">
                <div class="row-title">
                  <span class="row-title__text">Flight Documents</span>
                  <div class="row-title__alert" *ngIf="!booking?.agreement || !booking?.invoice">
                    <img src="assets/img/required.svg" alt="" class="pr-10">
                    Action required: (see below)
                  </div>
                </div>
                <div class="row-content">
                  <div class="content-title">
                    Charter contract
                  </div>
                  <div class="content-actions" *ngIf="booking?.agreement&&!booking?.agreementSigned">
                    <div class="actions-file">
                      <span class="actions-file__name">{{booking.agreement.split('/').pop()}} (Original)</span>
                      <img class="actions-file__ico pointer" (click)="deleteDocumentModal('agreement')" src="assets/img/cancel.svg" alt="">
                    </div>
                    <div class="actions-views">
                      <span class="actions-views__link" (click)="openDocument('agreement')">View</span>
                    </div>
                  </div>
                  <div class="content-actions" *ngIf="!booking?.agreement">
                    <span class="content-actions__empty">Waiting for contract ...</span>
                    <img src="assets/img/required.svg" alt="" class="pr-10">
                    <label for="fileAgreement">
                      <div class="content-actions__upload" matRipple>Upload</div>
                    </label>
                    <input hidden id="fileAgreement" name="fileAgreement" type="file" accept="application/pdf,image/jpeg,image/jpg,image/png" (change)="onFileSelect($event, 'agreement')" />
                  </div>
                  <div class="content-actions" *ngIf="booking?.agreement&&!booking?.agreementSigned">
                    <span class="content-actions__empty">Waiting for client signature</span>
                  </div>
                  <div class="content-actions" *ngIf="booking?.agreementSigned">
                    <div class="actions-file">
                      <span class="actions-file__name">{{booking.agreementSigned.split('/').pop()}} (Signed)</span>
                    </div>
                    <div class="actions-views">
                      <span class="actions-views__link" (click)="openDocument('agreement-signed')">View</span>
                    </div>
                  </div>
                </div>
                <div class="row-content">
                  <div class="content-title">
                    Charter invoice
                  </div>
                  <div class="content-actions" *ngIf="booking?.invoice">
                    <div class="actions-file">
                      <span class="actions-file__name">{{booking.invoice.split('/').pop()}}</span>
                      <img class="actions-file__ico pointer" (click)="deleteDocumentModal('invoice')" src="assets/img/cancel.svg" alt="">
                    </div>
                    <div class="actions-views">
                      <span class="actions-views__link" (click)="openDocument('invoice')">View</span>
                      <span *ngIf="booking?.paymentConfirmation" class="actions-views__link ml-5" (click)="openDocument('payment-confirmation')">PoP</span>
                      <ng-container *ngIf="!booking?.paymentConfirmation">
                        <label class="d-f ai-c ml-10" for="filePaymentConfirmation">
                          <div class="content-actions__upload" matRipple>Upload POP</div>
                        </label>
                        <input hidden id="filePaymentConfirmation" name="filePaymentConfirmation" type="file" accept="application/pdf,image/jpeg,image/jpg,image/png" (change)="onFileSelect($event, 'payment-confirmation')" />
                      </ng-container>
                    </div>
                  </div>
                  <div class="content-actions" *ngIf="!booking?.invoice">
                    <span class="content-actions__empty">Waiting for invoice ...</span>
                    <img src="assets/img/required.svg" alt="" class="pr-10">
                    <label for="fileInvoice">
                      <div class="content-actions__upload" matRipple>Upload</div>
                    </label>
                    <input hidden id="fileInvoice" name="fileInvoice" type="file" accept="application/pdf,image/jpeg,image/jpg,image/png" (change)="onFileSelect($event, 'invoice')" />
                  </div>
                </div>
                <!-- <div class="row-content">
                  <div class="content-title">
                    Flight Brief
                  </div>
                  <div class="content-actions" *ngIf="booking?.invoice">
                    <div class="actions-file">
                      <span class="actions-file__name">{{booking.invoice.split('/').pop()}}</span>
                      <img class="actions-file__ico pointer" (click)="deleteDocumentModal('invoice')" src="assets/img/cancel.svg" alt="">
                    </div>
                    <div class="actions-views">
                      <span class="actions-views__link" (click)="openDocument('invoice')">View</span>
                    </div>
                  </div>
                  <div class="content-actions" *ngIf="!booking?.invoice">
                    <span class="content-actions__empty">Waiting for invoice ...</span>
                    <img src="assets/img/required.svg" alt="" class="pr-10">
                    <label for="fileInvoice">
                      <div class="content-actions__upload" matRipple>Upload</div>
                    </label>
                    <input hidden id="fileInvoice" name="fileInvoice" type="file" accept="application/pdf,image/jpeg,image/jpg,image/png" (change)="onFileSelect($event, 'invoice')" />
                  </div>
                </div> -->
              </div>


              <div class="documents-row" *ngFor="let bookingLeg of booking?.bookingLegs; index as j">
                <div class="row-title">
                  <span class="row-title__text">Passengers documents LEG-{{j+1}}</span>
                  <button mat-stroked-button color="accent" type="button" (click)="openIncreasePassengersNumberModal(j)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M10 3.3334V16.6667M16.6667 10.0001L3.33337 10.0001" stroke="#B4A27F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span class="ml-10">Add passenger</span>
                  </button>
                </div>

                <div class="row-passenger" *ngFor="let p of bookingLeg.passengers | passengersType : 'adult' as adults; index as k">
                  <mat-expansion-panel (expandedChange)="toggleExpand($event.valueOf(), j, 'adults', k)" [expanded]="expanded[j].adults.includes(k)" class="passenger" [togglePosition]="'before'">
                    <mat-expansion-panel-header style="transition: none;" [expandedHeight]="'70px'" [collapsedHeight]="'70px'">
                      <div class="d-f ai-c">
                        <div>
                          <div class="title">Adult {{k+1}}</div>
                          <div class="name" *ngIf="p.id">{{p.title}} {{p.firstName}} {{p.lastName}}</div>
                        </div>
                        <button *ngIf="!isOperator" mat-icon-button [matMenuTriggerFor]="more" (click)="$event.stopPropagation();">
                          <img src="assets/img/more_vert_black.svg" alt="" class="fill-gold">
                        </button>
                        <mat-menu #more="matMenu">
                          <ng-container *ngIf="p.id">
                            <div class="d-f ai-c f-ch" mat-menu-item (click)="$event.stopPropagation(); fileField.click()">
                              <span>Upload documents</span>
                            </div>
                            <input type="file" accept="application/pdf,image/jpeg,image/jpg,image/png" #fileField (click)="$event.stopPropagation();" (change)="uploadPassengerDocuments($any($event).target.files, p)" hidden multiple />
                          </ng-container>
                          <div class="d-f ai-c f-ch" mat-menu-item (click)="clearPassengerData(j, p)" *ngIf="p.id">
                            <span>Clear passenger data</span>
                          </div>
                          <div class="d-f ai-c f-ch" mat-menu-item (click)="openChangePassengerType(j, p)" *ngIf="p.id">
                            <span>Change passenger type</span>
                          </div>
                          <div class="d-f ai-c f-ch" mat-menu-item (click)="clearPassengerData(j, p, true)">
                            <span>Remove Passenger</span>
                          </div>
                        </mat-menu>
                      </div>
                      <div class="col ai-fe">
                        <ng-container *ngIf="p.id">
                          <div class="files">{{p.documents.length}} files</div>
                          <div class="download" (click)="$event.stopPropagation(); downloadZip(p, bookingLeg.id)">DOWNLOAD DOCUMENTS</div>
                        </ng-container>
                        <div *ngIf="!p.id && isOperator" class="files">Passenger not selected</div>
                        <mat-form-field *ngIf="!p.id && !isOperator" appearance="outline" color="accent" class="item__select" (click)="$event.stopPropagation()">
                          <mat-label>Select passenger</mat-label>
                          <mat-select (selectionChange)="setPassenger(j, $event.value)">
                            <ng-container *ngIf="passengers$ | async | passengersType : p.type as passengers">
                              <ng-container *ngIf="filterPassenger(passengers, adults) as filtered">
                                <mat-option class="option" [value]="cn" *ngFor="let cn of filtered">
                                  {{cn.title}} {{cn.firstName}} {{cn.lastName}}
                                </mat-option>
                                <mat-option *ngIf="!filtered.length" disabled>Please add adult passenger</mat-option>
                              </ng-container>
                            </ng-container>
                            <!-- <button mat-stroked-button color="accent" class="of-hide wp-100" (click)="addNewPassenger(p.type, p)">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M10 3.3334V16.6667M16.6667 10.0001L3.33337 10.0001" stroke="#B4A27F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                              <span class="ml-10">Add new</span>
                            </button> -->
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </mat-expansion-panel-header>
                    <div class="document" *ngFor="let d of p.documents; index as r">
                      <div class="document__name">{{d.name}}</div>
                      <div class="document__actions">
                        <img src="assets/img/delete.svg" *ngIf="!isOperator" (click)="deletePassengerDocument(p.id, d.id)" alt="">
                        <img src="assets/img/rename.svg" *ngIf="!isOperator" (click)="updatePassengerDocument(p.id, d)" alt="">
                        <img src="assets/img/download.svg" (click)="openPassengerDocument(p.id, d.id,d.name)" alt="">
                        <img src="assets/img/view.svg" (click)="openPassengerDocument(p.id, d.id)" alt="">
                      </div>
                    </div>
                    <div *ngIf="!p.documents?.length" class="d-f ai-c jc-c op-06 h-50">No passenger documents</div>
                  </mat-expansion-panel>
                </div>
                <div class="row-passenger" *ngFor="let p of bookingLeg.passengers | passengersType : 'child' as childs; index as k">
                  <mat-expansion-panel (expandedChange)="toggleExpand($event.valueOf(), j, 'childs', k)" [expanded]="expanded[j].childs.includes(k)" class="passenger" [togglePosition]="'before'">
                    <mat-expansion-panel-header style="transition: none;" [expandedHeight]="'70px'" [collapsedHeight]="'70px'">
                      <div class="d-f ai-c">
                        <div>
                          <div class="title">Child {{k+1}}</div>
                          <div class="name" *ngIf="p.id">{{p.title}} {{p.firstName}} {{p.lastName}}</div>
                        </div>
                        <button *ngIf="!isOperator" mat-icon-button [matMenuTriggerFor]="more" (click)="$event.stopPropagation();">
                          <img src="assets/img/more_vert_black.svg" alt="" class="fill-gold">
                        </button>
                        <mat-menu #more="matMenu">
                          <ng-container *ngIf="p.id">
                            <div class="d-f ai-c f-ch" mat-menu-item (click)="$event.stopPropagation(); fileField.click()">
                              <span>Upload documents</span>
                            </div>
                            <input type="file" accept="application/pdf,image/jpeg,image/jpg,image/png" #fileField (click)="$event.stopPropagation();" (change)="uploadPassengerDocuments($any($event).target.files, p)" hidden multiple />
                          </ng-container>
                          <div class="d-f ai-c f-ch" mat-menu-item (click)="clearPassengerData(j, p)" *ngIf="p.id">
                            <span>Clear passenger data</span>
                          </div>
                          <div class="d-f ai-c f-ch" mat-menu-item (click)="openChangePassengerType(j, p)" *ngIf="p.id">
                            <span>Change passenger type</span>
                          </div>
                          <div class="d-f ai-c f-ch" mat-menu-item (click)="clearPassengerData(j, p, true)">
                            <span>Remove Passenger</span>
                          </div>
                        </mat-menu>
                      </div>
                      <div class="col ai-fe">
                        <ng-container *ngIf="p.id">
                          <div class="files">{{p.documents.length}} files</div>
                          <div class="download" (click)="$event.stopPropagation(); downloadZip(p, bookingLeg.id)">DOWNLOAD DOCUMENTS</div>
                        </ng-container>
                        <div *ngIf="!p.id && isOperator" class="files">Passenger not selected</div>
                        <mat-form-field *ngIf="!p.id && !isOperator" appearance="outline" color="accent" class="item__select" (click)="$event.stopPropagation()">
                          <mat-label>Select passenger</mat-label>
                          <mat-select (selectionChange)="setPassenger(j, $event.value)">
                            <ng-container *ngIf="passengers$ | async | passengersType : p.type as passengers">
                              <ng-container *ngIf="filterPassenger(passengers, childs) as filtered">
                                <mat-option class="option" [value]="cn" *ngFor="let cn of filtered">
                                  {{cn.title}} {{cn.firstName}} {{cn.lastName}}
                                </mat-option>
                                <mat-option *ngIf="!filtered.length" disabled>Please add child passenger</mat-option>
                              </ng-container>
                            </ng-container>
                            <!-- <button mat-stroked-button color="accent" class="of-hide wp-100" (click)="addNewPassenger(p.type, p)">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M10 3.3334V16.6667M16.6667 10.0001L3.33337 10.0001" stroke="#B4A27F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                              <span class="ml-10">Add new</span>
                            </button> -->
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </mat-expansion-panel-header>
                    <div class="document" *ngFor="let d of p.documents; index as r">
                      <div class="document__name">{{d.name}}</div>
                      <div class="document__actions">
                        <img src="assets/img/delete.svg" *ngIf="!isOperator" (click)="deletePassengerDocument(p.id, d.id)" alt="">
                        <img src="assets/img/rename.svg" *ngIf="!isOperator" (click)="updatePassengerDocument(p.id, d)" alt="">
                        <img src="assets/img/download.svg" (click)="openPassengerDocument(p.id, d.id,d.name)" alt="">
                        <img src="assets/img/view.svg" (click)="openPassengerDocument(p.id, d.id)" alt="">
                      </div>
                    </div>
                    <div *ngIf="!p.documents?.length" class="d-f ai-c jc-c op-06 h-50">No passenger documents</div>
                  </mat-expansion-panel>
                </div>
                <div class="row-passenger" *ngFor="let p of bookingLeg.passengers | passengersType : 'infant' as infants; index as k">
                  <mat-expansion-panel (expandedChange)="toggleExpand($event.valueOf(), j, 'infants', k)" [expanded]="expanded[j].infants.includes(k)" class="passenger" [togglePosition]="'before'">
                    <mat-expansion-panel-header style="transition: none;" [expandedHeight]="'70px'" [collapsedHeight]="'70px'">
                      <div class="d-f ai-c">
                        <div>
                          <div class="title">Infant {{k+1}}</div>
                          <div class="name" *ngIf="p.id">{{p.title}} {{p.firstName}} {{p.lastName}}</div>
                        </div>
                        <button *ngIf="!isOperator" mat-icon-button [matMenuTriggerFor]="more" (click)="$event.stopPropagation();">
                          <img src="assets/img/more_vert_black.svg" alt="" class="fill-gold">
                        </button>
                        <mat-menu #more="matMenu">
                          <ng-container *ngIf="p.id">
                            <div class="d-f ai-c f-ch" mat-menu-item (click)="$event.stopPropagation(); fileField.click()">
                              <span>Upload documents</span>
                            </div>
                            <input type="file" accept="application/pdf,image/jpeg,image/jpg,image/png" #fileField (click)="$event.stopPropagation();" (change)="uploadPassengerDocuments($any($event).target.files, p)" hidden multiple />
                          </ng-container>
                          <div class="d-f ai-c f-ch" mat-menu-item (click)="clearPassengerData(j, p)" *ngIf="p.id">
                            <span>Clear passenger data</span>
                          </div>
                          <div class="d-f ai-c f-ch" mat-menu-item (click)="openChangePassengerType(j, p)" *ngIf="p.id">
                            <span>Change passenger type</span>
                          </div>
                          <div class="d-f ai-c f-ch" mat-menu-item (click)="clearPassengerData(j, p, true)">
                            <span>Remove Passenger</span>
                          </div>
                        </mat-menu>
                      </div>
                      <div class="col ai-fe">
                        <ng-container *ngIf="p.id">
                          <div class="files">{{p.documents.length}} files</div>
                          <div class="download" (click)="$event.stopPropagation(); downloadZip(p, bookingLeg.id)">DOWNLOAD DOCUMENTS</div>
                        </ng-container>
                        <div *ngIf="!p.id && isOperator" class="files">Passenger not selected</div>
                        <mat-form-field *ngIf="!p.id && !isOperator" appearance="outline" color="accent" class="item__select" (click)="$event.stopPropagation()">
                          <mat-label>Select passenger</mat-label>
                          <mat-select (selectionChange)="setPassenger(j, $event.value)">
                            <ng-container *ngIf="passengers$ | async | passengersType : p.type as passengers">
                              <ng-container *ngIf="filterPassenger(passengers, infants) as filtered">
                                <mat-option class="option" [value]="cn" *ngFor="let cn of filtered">
                                  {{cn.title}} {{cn.firstName}} {{cn.lastName}}
                                </mat-option>
                                <mat-option *ngIf="!filtered.length" disabled>Please add infant passenger</mat-option>
                              </ng-container>
                            </ng-container>
                            <!-- <button mat-stroked-button color="accent" class="of-hide wp-100" (click)="addNewPassenger(p.type, p)">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M10 3.3334V16.6667M16.6667 10.0001L3.33337 10.0001" stroke="#B4A27F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                              <span class="ml-10">Add new</span>
                            </button> -->
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </mat-expansion-panel-header>
                    <div class="document" *ngFor="let d of p.documents; index as r">
                      <div class="document__name">{{d.name}}</div>
                      <div class="document__actions">
                        <img src="assets/img/delete.svg" *ngIf="!isOperator" (click)="deletePassengerDocument(p.id, d.id)" alt="">
                        <img src="assets/img/rename.svg" *ngIf="!isOperator" (click)="updatePassengerDocument(p.id, d)" alt="">
                        <img src="assets/img/download.svg" (click)="openPassengerDocument(p.id, d.id,d.name)" alt="">
                        <img src="assets/img/view.svg" (click)="openPassengerDocument(p.id, d.id)" alt="">
                      </div>
                    </div>
                    <div *ngIf="!p.documents?.length" class="d-f ai-c jc-c op-06 h-50">No passenger documents</div>
                  </mat-expansion-panel>
                </div>
                <div class="row-passenger" *ngFor="let p of bookingLeg.passengers | passengersType : 'pet' as pets; index as k">
                  <mat-expansion-panel (expandedChange)="toggleExpand($event.valueOf(), j, 'pets', k)" [expanded]="expanded[j].pets.includes(k)" class="passenger" [togglePosition]="'before'">
                    <mat-expansion-panel-header style="transition: none;" [expandedHeight]="'70px'" [collapsedHeight]="'70px'">
                      <div class="d-f ai-c">
                        <div>
                          <div class="title">Pet {{k+1}}</div>
                          <div class="name" *ngIf="p.id">{{p.title}} {{p.firstName}} {{p.lastName}}</div>
                        </div>
                        <button *ngIf="!isOperator" mat-icon-button [matMenuTriggerFor]="more" (click)="$event.stopPropagation();">
                          <img src="assets/img/more_vert_black.svg" alt="" class="fill-gold">
                        </button>
                        <mat-menu #more="matMenu">
                          <ng-container *ngIf="p.id">
                            <div class="d-f ai-c f-ch" mat-menu-item (click)="$event.stopPropagation(); fileField.click()">
                              <span>Upload documents</span>
                            </div>
                            <input type="file" accept="application/pdf,image/jpeg,image/jpg,image/png" #fileField (click)="$event.stopPropagation();" (change)="uploadPassengerDocuments($any($event).target.files, p)" hidden multiple />
                          </ng-container>
                          <div class="d-f ai-c f-ch" mat-menu-item (click)="clearPassengerData(j, p)" *ngIf="p.id">
                            <span>Clear passenger data</span>
                          </div>
                          <div class="d-f ai-c f-ch" mat-menu-item (click)="openChangePassengerType(j, p)" *ngIf="p.id">
                            <span>Change passenger type</span>
                          </div>
                          <div class="d-f ai-c f-ch" mat-menu-item (click)="clearPassengerData(j, p, true)">
                            <span>Remove Passenger</span>
                          </div>
                        </mat-menu>
                      </div>
                      <div class="col ai-fe">
                        <ng-container *ngIf="p.id">
                          <div class="files">{{p.documents.length}} files</div>
                          <div class="download" (click)="$event.stopPropagation(); downloadZip(p, bookingLeg.id)">DOWNLOAD DOCUMENTS</div>
                        </ng-container>
                        <div *ngIf="!p.id && isOperator" class="files">Pet not selected</div>
                        <mat-form-field *ngIf="!p.id && !isOperator" appearance="outline" color="accent" class="item__select" (click)="$event.stopPropagation()">
                          <mat-label>Select pet</mat-label>
                          <mat-select (selectionChange)="setPassenger(j, $event.value)">
                            <ng-container *ngIf="passengers$ | async | passengersType : p.type as passengers">
                              <ng-container *ngIf="filterPassenger(passengers, pets) as filtered">
                                <mat-option class="option" [value]="cn" *ngFor="let cn of filtered">
                                  {{cn.title}} {{cn.firstName}} {{cn.lastName}}
                                </mat-option>
                                <mat-option *ngIf="!filtered.length" disabled>Please add pet</mat-option>
                              </ng-container>
                            </ng-container>
                            <!-- <button mat-stroked-button color="accent" class="of-hide wp-100" (click)="addNewPassenger(p.type, p)">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M10 3.3334V16.6667M16.6667 10.0001L3.33337 10.0001" stroke="#B4A27F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                              <span class="ml-10">Add new</span>
                            </button> -->
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </mat-expansion-panel-header>
                    <div class="document" *ngFor="let d of p.documents; index as r">
                      <div class="document__name">{{d.name}}</div>
                      <div class="document__actions">
                        <img src="assets/img/delete.svg" *ngIf="!isOperator" (click)="deletePassengerDocument(p.id, d.id)" alt="">
                        <img src="assets/img/rename.svg" *ngIf="!isOperator" (click)="updatePassengerDocument(p.id, d)" alt="">
                        <img src="assets/img/download.svg" (click)="openPassengerDocument(p.id, d.id,d.name)" alt="">
                        <img src="assets/img/view.svg" (click)="openPassengerDocument(p.id, d.id)" alt="">
                      </div>
                    </div>
                    <div *ngIf="!p.documents?.length" class="d-f ai-c jc-c op-06 h-50">No pet documents</div>
                  </mat-expansion-panel>
                </div>

              </div>




              <div class="documents-row" *ngIf="booking?.docs">
                <div class="row-title">
                  <span class="row-title__text">Other documents</span>
                </div>
                <div class="row-content" *ngFor="let doc of booking.docs; index as i">
                  <div class="content-actions">
                    <div class="actions-file">
                      <span class="actions-file__name">{{doc.split('/').pop()}}</span>
                      <img class="actions-file__ico pointer" (click)="deleteDocumentModal('docs', i)" src="assets/img/cancel.svg" alt="">
                    </div>
                    <div class="actions-views">
                      <span class="actions-views__link" (click)="openDocument('documents', i)">View</span>
                    </div>
                  </div>
                </div>
                <div class="row-footer">
                  <label for="file" class="pointer">+ Upload aditional documents</label>
                  <input hidden id="file" name="file" type="file" accept="application/pdf,image/jpeg,image/jpg,image/png" (change)="onFileSelect($event)" #fileInput multiple />
                </div>
              </div>
            </div>
          </div>
          <div class="right-footer">
            <div class="footer-progress">
              <div class="progress-bar" *ngIf="booking?.status === 'WaitingForDeparture' || booking?.status === 'InFlight' || booking?.status === 'Mixed' || booking?.status === 'Landed'">Thank you very much for your efforts. All data is transferred to the user.</div>
              <div class="progress-bar">
                <lib-flight-progress [status]="booking?.status === 'WaitingForPayment' && booking?.invoice && booking?.paymentConfirmation && '5' || booking?.status"></lib-flight-progress>
              </div>
            </div>
            <div class="footer-action">
              <div class="action-required" *ngIf="(!booking?.agreement||!booking?.invoice&&booking?.agreementSigned)&&booking?.status !== 'Mixed'&&booking?.status !== 'WaitingForServiceFee'">
                <img src="assets/img/required.svg" alt="" class="pr-10">
                <span>User awaiting documents from you</span>
              </div>
              <button *ngIf="booking?.status === 'WaitingForAgreement' || booking?.status === 'CheckingAgreement' || booking?.status === 'WaitingForPayment' || booking?.status === 'new' || booking?.status === 'PreparingForFlight'" mat-button class="action-status" [disabled]="booking?.status !== 'CheckingAgreement' && (!booking?.agreement||!booking?.invoice||!booking?.agreementSigned)" (click)="setNextStatus(booking);">{{getButtonTextByStatus(booking?.status)}}</button>
              <button *ngIf="booking?.status === 'WaitingForDeparture' || booking?.status === 'InFlight' || booking?.status === 'Landed' || booking?.status === 'Mixed'" mat-button class="action-status" [routerLink]="isOperator ? '/frps' : '/requests/all-requests'">VIEW OTHER REQUESTS</button>
              <span class="footer-action__label" *ngIf="booking?.status === 'WaitingForSignature'">WAITING FOR SIGNATURE</span>
              <span class="footer-action__label" *ngIf="booking?.status === 'WaitingForServiceFee'">WAITING FOR SERVICE FEE</span>
            </div>
          </div>
        </mat-tab>
        <mat-tab *ngIf="!isOperator">
          <ng-template mat-tab-label>
            <span>Chat with client</span>
            <span class="counter" *ngIf="unReadCount">{{unReadCount}}</span>
          </ng-template>
          <div class="right-content tab-chat">
            <lib-chat-component [isHidden]="currentTab !== 1" [offerId]="booking?.bids?.[0]?.offer"></lib-chat-component>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BookingsService } from '../../../services/api/bookings.service';
import { RatesService } from '../../../services/api/rates.service';
import { first } from 'rxjs';
import { AdditionalServicesService } from '../../../services/api/additional-services';
import { Service } from '../../widgets/services/services.component';

@Component({
  selector: 'lib-cancel-booking',
  templateUrl: './cancel-booking.component.html',
  styleUrls: ['./cancel-booking.component.scss'],
})
export class CancelBookingComponent implements OnInit {

  services: Service[] = []
  dialogForm = this.fb.group({
    coinsToReturn: [undefined, (f: AbstractControl) => {
      this.validatorTrim(f);
    }],
    comment: [undefined]
  });
  coinsToReturn!: number;
  bookingFeeRate!: number;
  bookingFee!: number;
  servicesTotalCost!: number;
  cashEquivalent!: number;
  currentRate!: number;
  priceCurCode = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CancelBookingComponent>,
    private fb: UntypedFormBuilder,
    private bookingsService: BookingsService,
    private ratesService: RatesService,
    private additionalServicesService: AdditionalServicesService,
  ) {
    this.dialogRef.backdropClick().subscribe(_ => {
      this.dialogRef.close();
    });
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') this.dialogRef.close()
    });
  }

  ngOnInit(): void {
    this.bookingsService.getRefundAmount(this.data.bookingId).pipe(first()).subscribe(resp => {
      this.servicesTotalCost = resp.servicesRefund.reduce((total: number, s: any) => Math.floor(s.refundTokens/100) + total, 0)
      this.bookingFee = Math.floor(resp.bookingRefund.tokens/100)
      this.bookingFeeRate = resp.bookingRefund.serviceFee * 100
      this.coinsToReturn = Math.floor(resp.bookingRefund.tokens/100) + this.servicesTotalCost
      this.dialogForm.get('coinsToReturn')?.patchValue(this.coinsToReturn);
    });

    this.ratesService.getCurrentRates().pipe(first()).subscribe(rates => {
      this.bookingsService.getBookingById(this.data.bookingId).pipe(first()).subscribe(booking => {
        this.priceCurCode = booking.bookingLegs[0].priceCurCode;
        this.currentRate = rates[this.priceCurCode];
        this.cashEquivalent = this.currentRate/100 * this.coinsToReturn;
      })
    })

    this.additionalServicesService.getAdditionalServices(this.data.bookingId).pipe(first()).subscribe((services: any) => {
      const paidServices = services.filter((s: any) => s.status === 'paid')
      this.services = paidServices.sort((a: any, b: any) => new Date(a.created).getTime() - new Date(b.created).getTime());
    })
  }

  validatorTrim(formControl: AbstractControl): ValidationErrors | null {
    if (formControl.value && formControl.value !== this.coinsToReturn) {
      formControl.parent?.get('comment')?.addValidators(Validators.required);
      if (!formControl.parent?.value.comment) {
        formControl.parent?.get('comment')?.setErrors({required: true});
      } else {
        formControl.parent?.get('comment')?.setErrors(null);
      }
      formControl.parent?.get('comment')?.markAsTouched();
    } else {
      formControl.parent?.get('comment')?.clearValidators();
      formControl.parent?.get('comment')?.setErrors(null);
    }
    return null;
  }

  handleCoinsChange(): void {
    this.coinsToReturn = Number(this.dialogForm.get('coinsToReturn')?.value);
    this.cashEquivalent = this.currentRate/100 * this.coinsToReturn;
  }

  save(): void {
    if(this.dialogForm.get('coinsToReturn')?.value === this.coinsToReturn) {
      this.dialogRef.close({
        comment: this.dialogForm.value.comment
      });
    } else {
      this.dialogRef.close({
        comment: this.dialogForm.value.comment,
        coinsToReturn: this.dialogForm.value.coinsToReturn*100
      });
    }
  }

}

<mat-expansion-panel class="widget mat-elevation-z request-menu" (opened)="onWidgetExpand()" hideToggle>
  <mat-expansion-panel-header class="widget__header">
    <mat-panel-title>
      <div class="expand-icon" [class._active]="false">
        <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 4.5L0.250001 8.39712L0.250001 0.602886L7 4.5Z" fill="white" />
        </svg>
      </div>
      <div class="title">Services</div>
    </mat-panel-title>
    <mat-panel-description>
      <div class="description">
        <button mat-raised-button color="accent" class="d-f jc-c ai-c" (click)="$event.stopPropagation(); openServiceModal()">+ Create Service</button>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="widget__body">
    <div *ngIf="services?.length; else noServices">
      <div *ngFor="let service of services; let i = index" class="service-item">
        <div class="service-item__count">
          #{{ i + 1 }}
        </div>
        <div class="service-item__status-container">
          <div class="service-item__status" [ngClass]="'status-' + (service.deleted ? 'canceled' : service.status)">
            {{ service.deleted ? 'canceled' : (statusLabels[service.status] || service.status) }}
          </div>
          <div class="service-item__date">{{ service.created | date: 'dd.MM.YYYY HH:mm' }}</div>
        </div>
        <div class="service-item__content">
          <div class="service-item__content__title">{{ service.title }}</div>
          <div *ngIf="service.price === 0" class="service-item__content__price">FREE</div>
          <div *ngIf="service.price !== 0" class="service-item__content__price">{{ service.price }}<span class="service-item__content__currency">{{ service.currency }}</span></div>
        </div>
        <button mat-icon-button class="service-item__actions" [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" yPosition="below">
          <button *ngIf="service.status === 'draft' || service.status === 'waitingForPayment'" type="button" mat-menu-item (click)="chargeService(service)">
            <span>Charge</span>
          </button>
          <button type="button" mat-menu-item (click)="openServiceModal(service)">
            <span>{{!service.deleted && (service.status === 'draft' || service.status === 'waitingForPayment') ? 'Edit service' : 'View' }}</span>
          </button>
          <button *ngIf="service.status !== 'refunded'" type="button" mat-menu-item (click)="cancelService(service)">
            <span>{{ service.status === 'paid' ? 'Cancel & Refund' : (service.status === 'draft' ? 'Delete' : 'Cancel service')}}</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <ng-template #noServices>
      <p class="no-services">No services yet</p>
    </ng-template>
  </div>
</mat-expansion-panel>

<div class="modal-header">
    <span class="modal-header__text">Cancel booking</span>
    <button mat-icon-button mat-dialog-close>
        <img src="assets/img/cancel.svg" alt="">
    </button>
</div>

<form class="modal-container" [formGroup]="dialogForm">
    <div *ngIf="services.length">
        <div class="modal-container__text">
            You want to cancel a booking that contains already paid services
        </div>
        
        <div class="service-list">
            <span class="service-label">Service name</span>
            <div *ngFor="let service of services" class="service-item">
                <span>{{ service.title }}</span>
                <span class="service-price"><img src="assets/img/opes-coin.svg" alt=""> {{ service.tokens }}</span>
            </div>
        </div>
        
        <div class="separator"></div>

        <div class="total-cost">
            <span class="service-label">Total cost of services</span>
            <span class="total-amount"><img src="assets/img/opes-coin.svg" alt=""> {{ servicesTotalCost }}</span>
        </div>
        
        <div class="fee">
            <span class="service-label">OPES JET Fee ({{bookingFeeRate}} %)</span>
            <span class="fee-amount"><img src="assets/img/opes-coin.svg" alt=""> {{bookingFee}}</span>
        </div>
    </div>
    
    <div class="return-tokens">
        <span class="modal-container__text">
            Specify the amount of tokens you want to return to the account after cancellation.
        </span>
        <div class="return-value">
            <div class="d-f">
            <img src="assets/img/opes-coin.svg" alt="">
                <mat-form-field appearance="outline" class="refund-input">
                    <input matInput (change)="handleCoinsChange()" (input)="handleCoinsChange()" type="number" formControlName="coinsToReturn" required>
                </mat-form-field>
            </div>
            <span *ngIf="priceCurCode && cashEquivalent">
                ({{ cashEquivalent | currency: priceCurCode + ' ' : 'symbol' : '1.0-0'}})
            </span>
        </div>
    </div>
    
    <div>
        <label class="service-label">Comments</label>
        <mat-form-field class="comment" appearance="outline">
            <textarea matInput placeholder="" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="6" cdkAutosizeMaxRows="6" formControlName="comment"></textarea>
        </mat-form-field>
    </div>
</form>

<div class="modal-bottom">
	<button class="modal-bottom__btn d-f jc-c ai-c cancel" mat-stroked-button mat-dialog-close>No</button>
	<button mat-raised-button color="accent" class="modal-bottom__btn d-f jc-c ai-c ml-10" [disabled]="dialogForm.invalid" (click)="save()">Yes</button>
</div>
